import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/amuller/github-tkd/website-carbon/website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`We implement our solutions using the following pattern:`}</p>
    </PageDescription>
    <Row mdxType="Row">
  <Column colMd={6} colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABxghiP//EABUQAQEAAAAAAAAAAAAAAAAAABEg/9oACAEBAAEFAiv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAADAQEAAAAAAAAAAAAAAAAAAREgQf/aAAgBAQABPyHgig0rj//aAAwDAQACAAMAAAAQQw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAACAgMAAAAAAAAAAAAAAAAAEQEhIHGB/9oACAEBAAE/EE7LFw2REa3h/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6574d394c553d089b157edb93df3d3a6/b5e35/DataScience_process.webp 288w", "/static/6574d394c553d089b157edb93df3d3a6/0bf43/DataScience_process.webp 576w", "/static/6574d394c553d089b157edb93df3d3a6/73abe/DataScience_process.webp 1152w", "/static/6574d394c553d089b157edb93df3d3a6/b9259/DataScience_process.webp 1728w", "/static/6574d394c553d089b157edb93df3d3a6/37df7/DataScience_process.webp 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6574d394c553d089b157edb93df3d3a6/2fc17/DataScience_process.jpg 288w", "/static/6574d394c553d089b157edb93df3d3a6/91537/DataScience_process.jpg 576w", "/static/6574d394c553d089b157edb93df3d3a6/a10f6/DataScience_process.jpg 1152w", "/static/6574d394c553d089b157edb93df3d3a6/495f1/DataScience_process.jpg 1728w", "/static/6574d394c553d089b157edb93df3d3a6/3cb6a/DataScience_process.jpg 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6574d394c553d089b157edb93df3d3a6/a10f6/DataScience_process.jpg",
              "alt": "Data Science process",
              "title": "Data Science process",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </Column>
    </Row>
    <p>{`During each project, we employ agile practices to implement each stage: `}</p>
    <h2>{`Problem Definition`}</h2>
    <p>{`In this stage, the problem is defined in clear, measurable terms. It involves working with stakeholders to identify the business problem or opportunity that needs to be addressed, limiting the scope of the project, and determining the success criteria.`}</p>
    <h2>{`Data Collection`}</h2>
    <p>{`Once the problem is defined, the next step is to identify the data sources that will be used to solve the problem. This involves collecting and storing the data in a way that is consistent with the project goals.`}</p>
    <h2>{`Data Preparation`}</h2>
    <p>{`In this stage, the data is transformed and cleaned to ensure it is accurate and ready for analysis. This may include removing duplicates, filling in missing values, and converting data types.`}</p>
    <h2>{`Exploratory Data Analysis (EDA)`}</h2>
    <p>{`In EDA, the data is explored to identify patterns, relationships, and trends that may be relevant to the problem being addressed. This can involve visualizing the data and performing statistical analysis.`}</p>
    <h2>{`Feature Engineering`}</h2>
    <p>{`Feature engineering is the process of selecting and transforming the variables in the data to create new, meaningful features that can be used to build predictive models.`}</p>
    <h2>{`Model Building`}</h2>
    <p>{`In this stage, predictive models are developed using statistical algorithms or machine learning techniques. The models are trained on the data and evaluated to determine their accuracy and performance.`}</p>
    <h2>{`Model Deployment`}</h2>
    <p>{`Once the model has been developed, it is deployed in a production environment where it can be used to make predictions or inform business decisions.`}</p>
    <h2>{`Model Monitoring and Maintenance`}</h2>
    <p>{`As data changes over time, the performance of the model can deteriorate. In this stage, the model is monitored to ensure it continues to perform well and is updated as needed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      